import React, { useEffect } from 'react'

import gsap from 'gsap'

function Leo({choose,setChoose}) {

    useEffect(() => {
        
    },[])

    return (
        <div className='leo row row-cols-md-1'>
            <div className="arrow" onClick={e => setChoose("Home")}>
                <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="lay1 col-sm-12">
                <div className="card-body h-100">
                    <img src="./l/l1.jpg" className='w-100 h-100' alt="" />
                    <div className="anim"></div>
                </div>
            </div>
            <div className="layer col-sm-12">
                <div className="col h-100">
                    <div className="row row-cols-md-2 h-100">
                        <div className="col-md-4 layvh">
                            <div className="col h-100">
                                <img src="./l/l2.jpg" className='w-100 h-100' alt="" />
                            </div>
                        </div>
                        <div className="col-md-8 lay2">
                            <div className="col h-50 pb-sm-1">
                                <div className="container h-100">
                                    <div className="row p-sm-0 h-100">
                                        <div className="col-5 h-100">
                                            <div className="col h-100 "> 
                                                <img src="./l/l3.jpg" className='w-100 h-100' alt="" />
                                            </div>
                                        </div>
                                        <div className="col-7 offset-md-0 h-100">
                                            <div className="col h-100 ">
                                                <div className="row h-100">
                                                    <div className="col-sm-12 h-50">
                                                        <div className="col pb-sm-1 h-100">
                                                            <div className="col h-100">
                                                                <img src="./l/l7.png" className='w-100 h-100' alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 h-50">
                                                        <div className="col pt-sm-1 h-100">
                                                            <div className="col h-100">
                                                                <img src="./l/l6.png" className='w-100 h-100' alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col h-50 pt-sm-1">
                                <div className="container h-100">
                                    <div className="row row-cols-md-2 p-sm-0 h-100">
                                        <div className="col-md-8 h-100">
                                            <div className="col h-100">
                                                <img src="./l/l4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-4 h-100 offset-md-0">
                                            <div className="col h-100">
                                                <img src="./l/l5.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leo