import React, { useEffect } from 'react'

function Einstein() {
    
    useEffect(() => {
        
    })

    return (
        <div className='einstein'>
            
        </div>
    )
}

export default Einstein