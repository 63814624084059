// import logo from './logo.svg';
import Home from './Main/Home';
import './scss/home.scss'
import './scss/einstein.scss'
import './scss/newton.scss'
import './scss/leo.scss'

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
