import React, { useEffect, useState } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Leo from './Leo'
import Einstein from './Einstein'
import Newton from './Newton'
import { Back, Power2, Sine } from 'gsap/all'

function Home() {
    
    const [choose,setChoose] = useState("Home")

    const listImages = ["./img/Leo.jpg","./img/Einstein.jpg","./img/Newton.jpg"]

    const handleChoose = e => {
        setChoose(e)
    }

    useEffect(() => {
        const container = document.querySelector('.home')

        container.addEventListener("mousemove",(e) => {
            var rect = container.getBoundingClientRect()
            let x = e.clientX - rect.x
            let y = e.clientY - rect.y

            gsap.to('.mouseCursor',{
                "--x-m":`${x}px`,
                "--y-m":`${y}px`,
                ease:"sine.out"
            })
            gsap.to('.mouseInside',{
                "--x-m-i":`${x}px`,
                "--y-m-i":`${y}px`,
                duration:0.3,
            })
        }) 

        const Images = document.querySelectorAll('.Images')
        const cursorInside = document.querySelector('.mouseInside')
        Images.forEach((elem,i) => {
            console.log(elem.dataset.set)
            elem.addEventListener("mouseleave",(e) => {
                cursorInside.classList.remove("mouseInsideEnlarge")
                console.log(cursorInside.lastChild)
                cursorInside.removeChild(cursorInside.lastChild)
            })
            elem.addEventListener("mouseenter",(e) => {
                cursorInside.classList.add('mouseInsideEnlarge')
                const createElement = document.createElement("h4")
                createElement.innerText = elem.dataset.set
                // console.log(elem.dataset.name)
                cursorInside.appendChild(createElement)
                console.log(cursorInside,createElement)
            })
        })


        listImages.forEach((src, i) => {
        var canvas = document.querySelectorAll("canvas")[i];

        let tl = gsap.timeline({})

        // tl.fromTo()
        if (i % 2 == 0) {
            Anim(-150,150)
        } else if ( i % 2 != 0) {
            Anim(150,-150)
        }

        function Anim(call,call2) {
            gsap.to(Images[i].children[2],{
                y:`${call}%`,
                ease:Power2.easeIn,
                duration:2,
            })

            gsap.fromTo(Images[i].children[1].children[0].children[0],{
                y:`${call2}%`,
            },{
                y:"0%",
                ease:Sine.easeOut,
                duration:1,
                stagger:1,
                delay:2,
            })
            gsap.to(Images[i].children[1].children[1],{
                "--line-width":"100%",
                ease:Sine.easeOut,
                duration:1,
                stagger:1,
                delay:2,
            })
        }



        var img = new Image();
        img.onload = waves;
        img.src = src;
        
        var running = true;
        // canvas.addEventListener("mouseover", function() { running = true; });
        // canvas.addEventListener("mouseout", function() { running = false; });

        function waves() {
            var ctx = canvas.getContext("2d"),
            w = canvas.width,
            h = canvas.height;


            ctx.drawImage(this, 0, 0);

            var o1 = new Osc(0.2),
            o2 = new Osc(0.2),
            o3 = new Osc(0.2), // osc. for vert
            o4 = new Osc(0.2),
            o5 = new Osc(0.2),
            o6 = new Osc(0.27), // osc. for hori

            // source grid lines
            x0 = 0,
            x1 = w * 0.25,
            x2 = w * 0.5,
            x3 = w * 0.75,
            x4 = w,
            y0 = 0,
            y1 = h * 0.25,
            y2 = h * 0.5,
            y3 = h * 0.75,
            y4 = h,

            // cache source widths/heights
            sw0 = x1,
            sw1 = x2 - x1,
            sw2 = x3 - x2,
            sw3 = x4 - x3,
            sh0 = y1,
            sh1 = y2 - y1,
            sh2 = y3 - y2,
            sh3 = y4 - y3,

            vcanvas = document.createElement("canvas"), // off-screen canvas for 2. pass
            vctx = vcanvas.getContext("2d");

            vcanvas.width = w;
            vcanvas.height = h; // set to same size as main canvas

            (function loop() {
            ctx.clearRect(0, 0, w, h);

            for (var y = 0; y < h; y++) {

                // segment positions
                var lx1 = x1 + o1.current(y * 0.2) * 2.5,
                lx2 = x2 + o2.current(y * 0.2) * 2,
                lx3 = x3 + o3.current(y * 0.2) * 1.5,

                // segment widths
                w0 = lx1,
                w1 = lx2 - lx1,
                w2 = lx3 - lx2,
                w3 = x4 - lx3;

                // draw image lines
                ctx.drawImage(img, x0, y, sw0, 1, 0, y, w0, 1);
                ctx.drawImage(img, x1, y, sw1, 1, lx1 - 0.5, y, w1 + 0.5, 1);
                ctx.drawImage(img, x2, y, sw2, 1, lx2 - 0.5, y, w2 + 0.5, 1);
                ctx.drawImage(img, x3, y, sw3, 1, lx3 - 0.5, y, w3 + 0.5, 1);
            }

            // pass 1 done, copy to off-screen canvas:
            vctx.clearRect(0, 0, w, h); // clear off-screen canvas (only if alpha)
            vctx.drawImage(canvas, 0, 0);
            ctx.clearRect(0, 0, w, h); // clear main (onlyif alpha)

            for (var x = 0; x < w; x++) {
                var ly1 = y1 + o4.current(x * 0.32),
                ly2 = y2 + o5.current(x * 0.3) * 2,
                ly3 = y3 + o6.current(x * 0.4) * 1.5;

                ctx.drawImage(vcanvas, x, y0, 1, sh0, x, 0, 1, ly1);
                ctx.drawImage(vcanvas, x, y1, 1, sh1, x, ly1 - 0.5, 1, ly2 - ly1 + 0.5);
                ctx.drawImage(vcanvas, x, y2, 1, sh2, x, ly2 - 0.5, 1, ly3 - ly2 + 0.5);
                ctx.drawImage(vcanvas, x, y3, 1, sh3, x, ly3 - 0.5, 1, y4 - ly3 + 0.5);
            }
            requestAnimationFrame(loop);
            })();
        }

        function Osc(speed) {

            var frame = 0;

            this.current = function(x) {
            if (running) {
                frame += 0.002 * speed;
            }
            return Math.sin(frame + x * speed * 10);
            };
        }
        });


    },[])

    return (
        <div className='home container-fluid d-flex align-items-center justify-content-center'>
            
            <div className="mouseCursor"></div>
            <div className="mouseInside"></div>

            
            <div className="banner row row-cols-sm-3 align-items-center justify-content-between"
                style={choose == "Home" ? {
                    display: "inherit"
                } : {
                    display:"none"
                }}
            >
                <div className="banner-img col-4 pt-sm-2">
                    <div className="container h-100 p-1">
                        <div className="Images position-relative" onClick={e => handleChoose("Leo")} data-set="Leonardo" >
                            {/* <img src="./img/Leo.jpg" className='w-100 h-100' alt="" /> */}
                            <canvas width="1000px" height="1000px"></canvas>
                            <div className="text h2 position-absolute">
                                <div className="another overflow-hidden">
                                    <div className="hide">
                                        Leonardo Da Vinci
                                    </div>
                                </div>
                                <div className="lines"></div>
                            </div>
                            <div className="dashing"></div>
                        </div>
                    </div>
                </div>
                <div className="banner-img col-4 pt-sm-2">
                    <div className="container h-100 p-1">
                        <div className="Images position-relative" onClick={e => handleChoose("Einstein")} data-set="Einstein" >
                            {/* <img src="./img/Einstein.jpg" className='w-100 h-100' alt="" /> */}
                            <canvas width="800px" height="1000px"></canvas>
                            <div className="text h2 position-absolute">
                                <div className="another overflow-hidden">
                                    <div className="hide">
                                        Albert Einstein
                                    </div>
                                </div>
                                <div className="lines"></div>
                            </div>
                            <div className="dashing"></div>
                        </div>
                    </div>
                </div>
                <div className="banner-img col-4 pt-sm-2">
                    <div className="container h-100 p-1">
                        <div className="Images position-relative" onClick={e => handleChoose("Newton")} data-set="Newton" >
                            {/* <img src="./img/Newton.jpg" className='w-100 h-100' alt="" /> */}
                            <canvas width="220px" height="220px"></canvas>
                            <div className="text h2 position-absolute">
                                <div className="another overflow-hidden">
                                    <div className="hide">
                                        Isaac Newton
                                    </div>
                                </div>
                                <div className="lines"></div>
                            </div>
                            <div className="dashing"></div>
                        </div>
                    </div>
                </div>
            </div>

            {choose == "Leo" ? <>
                <Leo choose={choose} setChoose={setChoose} ></Leo>
            </>:<></>}

            {choose == "Einstein" ? <>
            <Einstein choose={choose} setChoose={setChoose} ></Einstein>
            </>:<></>}

            {choose == "Newton" ? <>
                <Newton choose={choose} setChoose={setChoose} ></Newton>
            </>: <></>}

        </div>
    )
}

export default Home